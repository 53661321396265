import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: "navigation",
  class: "o-panel"
}
const _hoisted_2 = { class: "o-panel--spacedChildrenSmall" }
const _hoisted_3 = {
  key: 0,
  class: "a-heading2"
}
const _hoisted_4 = {
  key: 1,
  class: "a-paragraph"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "o-buttons" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = {
  key: "success",
  class: "o-panel"
}
const _hoisted_12 = { class: "o-panel--spacedChildrenSmall" }
const _hoisted_13 = { class: "a-confirmationNotice u-align-center u-justify-center" }
const _hoisted_14 = { class: "a-confirmationNotice__checkmark" }
const _hoisted_15 = {
  key: 0,
  class: "a-heading2 o-panel__heading u-text-center u-inline-auto"
}
const _hoisted_16 = {
  key: 1,
  class: "a-paragraph u-text-center"
}
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { key: 3 }
const _hoisted_19 = {
  key: 4,
  class: "u-text-center"
}
const _hoisted_20 = ["href"]
const _hoisted_21 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_property_favorite_card = _resolveComponent("o-property-favorite-card")!
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_portal = _resolveComponent("portal")!

  return ($options.isVisible)
    ? (_openBlock(), _createBlock(_component_portal, {
        key: 0,
        to: "modal"
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: `o-panel--${$data.currentDirection}`
          }, {
            default: _withCtx(() => [
              (!_ctx.currentPanel)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      ($props.texts.headline)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString($props.texts.headline), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      ($props.texts.description)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.texts.description), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      (_ctx.currentProperty)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_o_property_favorite_card, { property: _ctx.currentProperty }, null, 8 /* PROPS */, ["property"])
                          ]))
                        : ($props.property)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _createVNode(_component_o_property_favorite_card, { property: $props.property }, null, 8 /* PROPS */, ["property"])
                            ]))
                          : _createCommentVNode("v-if", true),
                      ($props.consent && $props.consent.purposeText)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            class: "a-small a-small--allowBreak o-fields__field",
                            innerHTML: $props.consent.purposeText
                          }, null, 8 /* PROPS */, _hoisted_7))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("a", {
                          href: $options.loginUrl,
                          class: "m-button m-button--fullWidth",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onLogin && $options.onLogin(...args)))
                        }, [
                          _createElementVNode("span", {
                            class: "m-button__text",
                            textContent: _toDisplayString($props.texts.loginText)
                          }, null, 8 /* PROPS */, _hoisted_10)
                        ], 8 /* PROPS */, _hoisted_9)
                      ])
                    ])
                  ]))
                : (_ctx.currentPanel === 'success')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_a_icon, { name: "check" })
                          ])
                        ]),
                        ($props.texts.savedHeadline)
                          ? (_openBlock(), _createElementBlock("h2", _hoisted_15, _toDisplayString($props.texts.savedHeadline), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        ($props.texts.savedDescription)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString($props.texts.savedDescription), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        (_ctx.currentProperty)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createVNode(_component_o_property_favorite_card, { property: _ctx.currentProperty }, null, 8 /* PROPS */, ["property"])
                            ]))
                          : ($props.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createVNode(_component_o_property_favorite_card, { property: $props.property }, null, 8 /* PROPS */, ["property"])
                              ]))
                            : _createCommentVNode("v-if", true),
                        (
                            $props.texts.receiptLinkToProfileUrl &&
                            $props.texts.receiptLinkToProfileName
                        )
                          ? (_openBlock(), _createElementBlock("p", _hoisted_19, [
                              _createElementVNode("a", {
                                href: $props.texts.receiptLinkToProfileUrl,
                                class: "m-button m-button--link m-button--underlined",
                                target: "_blank"
                              }, [
                                _createElementVNode("span", {
                                  class: "m-button__text",
                                  textContent: _toDisplayString($props.texts.receiptLinkToProfileName)
                                }, null, 8 /* PROPS */, _hoisted_21)
                              ], 8 /* PROPS */, _hoisted_20)
                            ]))
                          : _createCommentVNode("v-if", true)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
            ], undefined, true),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["name"])
        ], undefined, true),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}