
import { mapGetters, mapActions } from 'vuex';
import Format from '@/functions/format';
import { MINDWORKING_REDIRECT_TYPES } from '@/constants/mindworkingRedirectTypes.const';
import Vue, { PropType } from 'vue';
import LeadConsent from '@/interfaces/leadConsent.interface';
import oPropertyFavoriteCard from './PropertyFavoriteCard.vue';
import tracking from '@/functions/tracking';
import Property from '@/interfaces/property.interface';

interface Data {
    currentDirection: string;
    isLoading: boolean;
}

interface ITexts {
    headline: string;
    description: string;
    savedHeadline: string;
    savedDescription: string;
    receiptLinkToProfileName: string;
    receiptLinkToProfileUrl: string;
    loginText: string;
}

export default {
    components: {
        oPropertyFavoriteCard,
    },
    props: {
        redirected: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        redirectedFrom: {
            type: String as PropType<string>,
            default: () => '',
        },
        propertyId: {
            type: String as PropType<string>,
            default: () => '',
            required: false,
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
            required: false,
        },
        property: {
            type: Object as PropType<Property>,
            default: () => ({
                address: '',
                city: '',
                factsDesktop: [],
                factsMobile: [],
                fallbackImageUrl: '',
                hasNewPrice: false,
                images: [],
                isDanbolig: false,
                isNew: false,
                isSold: false,
                isUnderSale: false,
                luxurious: false,
                propertySize: '',
                openHouse: '',
                openHouseSignupRequired: '',
                soldDate: new Date(),
                type: '',
                url: '',
                zipCode: 0,
            }),
            required: false,
        },
        texts: {
            type: Object as PropType<ITexts>,
            default: () => ({
                headline: '',
                description: '',
                savedHeadline: '',
                savedDescription: '',
                receiptLinkToProfileName: '',
                receiptLinkToProfileUrl: '',
                loginText: '',
            }),
        },
        consent: {
            type: Object as PropType<LeadConsent>,
            default: () => ({
                id: '',
                purposeText: '',
            }),
        },
    },

    data(): Data {
        return {
            currentDirection: '',
            isLoading: false,
        };
    },

    mounted() {
        if (this.currentPanel === 'success') {
            this.setCurrentPanel('');
        }

        if (
            this.redirected &&
            this.redirectedFrom ===
                MINDWORKING_REDIRECT_TYPES.MW_FAVORITE_PROPERTY
        ) {
            Vue.nextTick(() => {
                const interval = 100; // Retry every 100 milliseconds
                const timeout = 10000; // Timeout after 10 seconds
                let elapsedTime = 0;

                const intervalId = setInterval(() => {
                    const bolig = this.currentProperty as Property;
                    if (
                        bolig &&
                        bolig.propertyId !== null &&
                        bolig.brokerId !== null
                    ) {
                        console.log('VI HAR EN BOLIG');
                        clearInterval(intervalId);

                        this.onSubmit();
                    } else {
                        elapsedTime += interval;
                        if (elapsedTime >= timeout) {
                            clearInterval(intervalId);
                            throw new Error('Operation timed out');
                        }
                    }
                }, interval);
            });
        }
    },

    computed: {
        ...mapGetters({
            currentPanel: 'favoriteProperty/currentPanel',
            currentProperty: 'user/currentProperty',
            favoriteProperties: 'user/favoriteProperties',
            favoriteProperty: 'user/favoriteProperty',
            fixedPanel: 'modal/fixed',
            modalShow: 'modal/show',
            modalType: 'modal/type',
        }),

        /**
         * Get first image.
         *
         * @return {string}
         */
        image(): string {
            if (!this.currentProperty.images?.length) {
                return this.currentProperty.fallbackImageUrl;
            }
            const image = this.currentProperty.images[0];
            return image && image.includes('deviceid')
                ? Format.formatMindworkingUrl(image ?? '', '500', '')
                : image ?? this.currentProperty.fallbackImageUrl;
        },

        /**
         * Check if property is external.
         *
         * @return {boolean}
         */
        isExternal(): boolean {
            return !this.currentProperty.isDanbolig;
        },

        openHouseFormatted(): string {
            const template = this.currentProperty.openHouseSignupRequired
                ? this.openHouseTexts.teaserSignup
                : this.openHouseTexts.teaser;
            return template.replace(
                '#naesteaabenthus#',
                this.currentProperty.openHouse ?? '',
            );
        },

        soldDate(): string {
            if (
                this.currentProperty.soldDate !== null &&
                this.currentProperty.soldDate !== undefined
            ) {
                const date = new Date(this.currentProperty.soldDate);
                const month = Format.monthShort(date);
                const year = Format.year(date);
                return `Solgt ${month} ${year}`;
            }

            return '';
        },

        /**
         * Check if the modal should be visible.
         *
         * @return {boolean}
         */
        isVisible(): boolean {
            return this.modalShow && this.modalType === 'favoriteProperty';
        },

        loginUrl(): string {
            // %2F = "/"
            // %3F = "?"
            // %3D = "="
            // %26 = "&"
            // %23 = "#"

            const currentPath = window.location.pathname;
            const encodedPath = encodeURIComponent(currentPath);

            const currentSearch = window.location.search;
            const encodedSearch = encodeURIComponent(currentSearch);

            let redirectString = '';

            if (currentSearch.includes('?')) {
                redirectString = '%26redirect%3D';
            } else {
                redirectString = '%3fredirect%3D';
            }

            const base =
                '/login/?redirectUrl=' +
                encodedPath +
                encodedSearch +
                redirectString +
                MINDWORKING_REDIRECT_TYPES.MW_FAVORITE_PROPERTY;

            return base;
        },
    },

    methods: {
        ...mapActions({
            setCurrentPanel: 'favoriteProperty/setCurrentPanel',
            setFavoriteProperty: 'user/setFavoriteProperty',
        }),

        onLogin(): void {
            const isPropertyPresentationPage =
                window.location.href.includes('/bolig/');

            tracking.trackRawEvent({
                event: 'trackCTA',
                eventData: {
                    category: 'Favoritter',
                    action: 'Klik paa Log ind/opret bruger',
                    label: `${isPropertyPresentationPage ? 'Boligpraesentation' : 'Preview'}`,
                },
            });
        },

        onSubmit(x: string): void {
            const isPropertyPresentationPage =
                window.location.href.includes('/bolig/');

            this.$store.dispatch('modal/open', 'favoriteProperty');

            this.currentDirection = 'next';
            this.setCurrentPanel('success');

            this.setFavoriteProperty({
                propertyId: this.currentProperty.propertyId,
                brokerId: this.currentProperty.brokerId,
            });

            tracking.trackRawEvent({
                event: 'trackCTA',
                eventData: {
                    category: 'Favoritter',
                    action: 'Gem som favorit gennemfoert',
                    label: `${isPropertyPresentationPage ? 'Boligpraesentation' : 'Preview'} - Ved Log ind/Opret bruger`,
                },
            });

            if (isPropertyPresentationPage) {
                window.history.replaceState(
                    {},
                    document.title,
                    window.location.pathname,
                );
            }
        },
    },
};
