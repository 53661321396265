
interface Data {
    showGrid: boolean;
}

export default {
    name: 'a-grid-overlay',
    data(): Data {
        return {
            showGrid: false,
        };
    },

    mounted(): void {
        if (process.env.NODE_ENV === 'production') {
            return;
        }
        document.addEventListener('keydown', this.onTriggerKeydown);
    },

    methods: {
        onTriggerKeydown(event: KeyboardEvent): void {
            if (
                event.shiftKey &&
                event.ctrlKey &&
                event.altKey &&
                event.code === 'KeyG'
            ) {
                this.showGrid = !this.showGrid;
            }
        },
    },
};
