import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_map = _resolveComponent("m-map")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            'o-propertyMap o-propertyPresentationMap__map': $props.isComingSoon,
            'o-neighborhoodMap': !$props.isComingSoon,
        })
  }, [
    _createVNode(_component_m_map, {
      class: _normalizeClass({
                'o-propertyMap__map': $props.isComingSoon,
                'o-neighborhoodMap__map': !$props.isComingSoon,
            }),
      brokers: $data.brokers,
      center: [$data.longitude, $data.latitude],
      "do-favorite-clusters": true,
      favorites: $data.favorites,
      "fit-neighborhoods": !!$props.municipalityId || !!$props.neighborhoodId,
      "municipality-polygon": $props.polygon,
      "is-loading": $data.isLoading,
      "max-bounds": $data.maxBounds,
      neighborhoods: $data.neighborhoods,
      properties: $data.properties,
      "map-type": 'neighborhoodmap',
      zoom: $data.zoom,
      texts: $props.texts,
      "is-coming-soon": $props.isComingSoon,
      onLoad: $options.onLoad,
      onMove: $options.onMove
    }, null, 8 /* PROPS */, ["class", "brokers", "center", "favorites", "fit-neighborhoods", "municipality-polygon", "is-loading", "max-bounds", "neighborhoods", "properties", "zoom", "texts", "is-coming-soon", "onLoad", "onMove"])
  ], 2 /* CLASS */))
}