
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import tracking from '@/functions/tracking';

export interface ITrackingData {
    name?: string;
    category?: string;
    action?: string;
    label?: string;
    formularIndgang?: string;
    formularSektion?: string;
    formularStepnavn?: string;
    maeglerId?: string;
}

export interface EventData {
    [key: string]: any;
}

export default {
    name: 'modal-opener',
    props: {
        modal: {
            type: String as PropType<string>,
            default: () => '',
        },
        btnLabel: {
            type: String as PropType<string>,
            default: () => '',
        },
        panel: {
            type: String as PropType<string>,
            default: () => '',
        },
        fixed: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        trackingData: {
            type: Object as PropType<ITrackingData>,
            default: () => ({
                name: '',
                category: '',
                action: '',
                label: '',
                formularIndgang: '',
                formularSektion: '',
                formularStepnavn: '',
                maeglerId: '',
            }),
        },
    },

    methods: {
        ...mapActions({
            setFixed: 'modal/setFixed',
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),

        /**
         * Handle click event.
         *
         * @return {void}
         */
        onClick(e: MouseEvent | TouchEvent): void {
            e.stopPropagation();
            e.preventDefault();

            if (this.modal) {
                this.$store.dispatch(`${this.modal}/open`, this.panel ?? '');
                this.setTrackingEntrance(
                    this.trackingData.formularIndgang ?? '',
                );
            }

            if (this.fixed) {
                this.setFixed(this.fixed);
            }

            if (this.trackingData.name) {
                const trackingEventData: EventData = {
                    event: this.trackingData.name,
                    eventData: {},
                };

                if (this.trackingData.category) {
                    trackingEventData.eventData.category =
                        this.trackingData.category;
                }

                if (this.trackingData.action) {
                    trackingEventData.eventData.action =
                        this.trackingData.action;
                }

                if (this.trackingData.label) {
                    trackingEventData.eventData.label = this.trackingData.label;
                }

                if (this.trackingData.formularIndgang) {
                    trackingEventData.eventData.formularIndgang =
                        this.trackingData.formularIndgang;
                }

                if (this.trackingData.formularStepnavn) {
                    trackingEventData.eventData.formularStepnavn =
                        this.trackingData.formularStepnavn;
                }

                if (this.trackingData.formularSektion) {
                    trackingEventData.eventData.formularSektion =
                        this.trackingData.formularSektion;
                }

                if (this.trackingData.maeglerId) {
                    trackingEventData.eventData.maeglerId =
                        this.trackingData.maeglerId;
                }

                tracking.trackRawEvent(trackingEventData);
            }
        },
    },
};
