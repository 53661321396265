import Property from '@/interfaces/property.interface';
import State from './interface';
import FavoriteProperty from '@/interfaces/favoriteProperty.interface';
import Api from '@/functions/api';
import FavoritePropertiesResponse from '@/interfaces/responses/favoritePropertiesResponse.interface';

export default {
    setFavoriteProperty(state: State, payload: FavoriteProperty): void {
        const isFound = state.favoriteProperties.some(
            (element: FavoriteProperty) => {
                if (
                    element.propertyId === payload.propertyId &&
                    element.brokerId === payload.brokerId
                ) {
                    return true;
                }
                return false;
            },
        );

        if (isFound) {
            Api.removeFromFavoriteProperties(payload).then(
                (response: FavoritePropertiesResponse) => {
                    state.favoriteProperties = response.properties;
                },
            );
        } else {
            Api.addToFavoriteProperties(payload).then(
                (response: FavoritePropertiesResponse) => {
                    state.favoriteProperties = response.properties;
                },
            );
        }
    },

    setIsLoggedIn(state: State, payload: boolean): void {
        state.isLoggedIn = payload;
    },

    setName(state: State, payload: string): void {
        state.name = payload;
    },

    /**
     * Set the current property.
     *
     * @param {State} state
     * @param {number} payload
     * @return {void}
     */
    setCurrentProperty(state: State, payload: Property | null): void {
        state.currentProperty = payload;
    },

    setFavoriteProperties(
        state: State,
        payload: FavoriteProperty[] | [],
    ): void {
        state.favoriteProperties = payload;
    },
};
