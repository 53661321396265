import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled", "aria-label"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_ctx.isLoggedIn)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: "button",
        class: _normalizeClass(["o-propertyPreview__favorite", { isFavorite: $options.propertyIsFavorite }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onClick($event))),
        disabled: $data.isSaving,
        "aria-label": `${$options.propertyIsFavorite ? 'Fjern' : 'Gem'} som favorit`
      }, [
        _createVNode(_component_a_icon, {
          name: "favorite_animated",
          class: "a-icon--favorite"
        }),
        _createElementVNode("span", {
          class: _normalizeClass(["o-propertyPreview__favorite__text", {
                animateIn: $data.addAnimationIn,
                animateOut: $data.addAnimationOut,
            }]),
          ref: "buttonText",
          textContent: _toDisplayString($data.buttonText),
          "aria-hidden": "true"
        }, null, 10 /* CLASS, PROPS */, _hoisted_2)
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        type: "button",
        class: "o-propertyPreview__favorite",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onNotLoggedInClick && $options.onNotLoggedInClick(...args)))
      }, [
        _createVNode(_component_a_icon, {
          name: "favorite_animated",
          class: "a-icon--favorite"
        }),
        _createElementVNode("span", {
          class: "o-propertyPreview__favorite__text",
          textContent: _toDisplayString($props.texts.saveLabel)
        }, null, 8 /* PROPS */, _hoisted_3)
      ]))
}