
import { PropType } from 'vue';
import { EventBus } from '@/functions/eventBus';

interface Data {
    isDesktop: boolean;
}

export default {
    name: 'a-element-scroll',
    props: {
        id: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            isDesktop: EventBus.isDesktop,
        };
    },

    beforeMount(): void {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    methods: {
        clicked(): void {
            const salesValuationForm =
                document.querySelector('.o-salesValuation');

            if (salesValuationForm) {
                salesValuationForm.scrollIntoView();
                return;
            }

            const element = document.getElementById(this.id) as HTMLElement;
            const parentSection = element.closest('section');

            if (parentSection && !this.isDesktop) {
                const nextElementSibling =
                    parentSection.nextElementSibling as HTMLDivElement;

                if (nextElementSibling !== null) {
                    nextElementSibling.scrollIntoView();
                } else {
                    const elem = document.querySelector(
                        `[data-id="${this.id}"]`,
                    );
                    elem?.scrollIntoView();
                }
                return;
            }

            const header = document.getElementsByClassName(
                'o-header',
            )[0] as HTMLElement;
            const headerStyle = window.getComputedStyle(header);
            const elementStyle = window.getComputedStyle(element);

            const top =
                element.getBoundingClientRect().bottom +
                parseInt(headerStyle.getPropertyValue('padding-top')) +
                parseInt(headerStyle.getPropertyValue('padding-bottom')) +
                (this.isDesktop
                    ? 0
                    : parseInt(
                          elementStyle.getPropertyValue('margin-bottom'),
                      )) -
                header.clientHeight;

            window.scrollTo({
                top,
                behavior: 'smooth',
            });
        },
    },
};
