// import Cookies from 'js-cookie';
// import Vue from 'vue';
// import Vuex from 'vuex';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import article from './store/article';
import broker from './store/broker';
import brokerSearch from './store/brokerSearch';
import buyerDirectory from './store/buyerDirectory';
import document from './store/document';
import employee from './store/employee';
import estimate from './store/estimate';
import homeEstimate from './store/homeEstimate';
import modal from './store/modal';
import projectSale from './store/projectSale';
import property from './store/property';
import pointsOfInterest from './store/pointsOfInterest';
import search from './store/search';
import searchAgent from './store/searchAgent';
import widget from './store/widget';
import favoriteProperty from './store/favoriteProperty';
import user from './store/user';

// Vue.use(Vuex);

const store = createStore({
    plugins: [
        // NOTE: Only one createPersistedState can exist pr. storage option (i.e localStorage)
        // createPersistedState({
        //     paths: [
        //         'estimate.property.addressId',
        //     ],
        //     storage: {
        //         getItem: key => Cookies.get(key),
        //         setItem: (key, value) => Cookies.set(key, value, { expires: 0.125, secure: false }),
        //         removeItem: key => Cookies.remove(key),
        //     },
        // }),
        createPersistedState({
            paths: [
                'estimate.parameters.amenityValue',
                'estimate.parameters.energyLevel',
                'estimate.parameters.exteriorCondition',
                'estimate.parameters.interiorCondition',
                'estimate.parameters.location',
                'estimate.estimates.user',
                'estimate.property.address',
                'estimate.broker.image',
                'search.area',
                'search.listPage',
                'search.mapHasInteraction',
                'search.page',
                'search.userLocation',
                'search.view',
                'search.isDrawingArea',
                'brokerSearch.view',
                'brokerSearch.activeBroker',
                'user.favoriteProperties',
                'user.isLoggedIn',
                'user.name',
            ],
            storage: window.sessionStorage,
        }),
        createPersistedState({
            paths: [
                'widget.widgets',
                'article.visited',
                'property.visited',
                'buyerDirectory.filters',
                'buyerDirectory.overlay',
                'estimate.property.addressId',
                'document',
                'pointsOfInterest.selectedPois',
                'pointsOfInterest.selectedTransportationMethod',
                'user.currentProperty',
                'searchAgent.agentName',
                'searchAgent.overlay',
            ],
            storage: window.localStorage,
        }),
    ],

    modules: <any>{
        article,
        broker,
        brokerSearch,
        estimate,
        homeEstimate,
        modal,
        projectSale,
        property,
        search,
        widget,
        employee,
        buyerDirectory,
        document,
        pointsOfInterest,
        favoriteProperty,
        user,
        searchAgent,
    },
});

export default store;
