/* eslint-disable */
export enum LeadTypes {
    ContactEmployee, // Contact broker, not employee, on specific case
    ContactEmployeeSpecific, // Contact employee on specific case
    Contact, // Contact broker, not employee
    ContactSpecific, // Contact employee
    OpenHouse,
    Presentation,
    PurchaseOffer,
    SalesMaterial, // Get sales material, broker not allowed to contact
    SalesMaterialWithContact, // Get sales material
    SalesValuation,
    SalesValuationShop, // Sales valuation for specific shop (broker)
    SalesValuationShopWeekend, // Sales valuation weekend for specific shop (broker)
    SalesValuationWeekend,
    SalesValuationDinGeo,
    HomeEstimate,
    BuyersAdvice,
    FindBuyer,
    SalesValuationHomeEstimate,
    SaveAsFavorite,
    ComingSoonContact,
    ComingSoonPresentation,
}
