import actions from './favoriteProperty/actions';
import getters from './favoriteProperty/getters';
import mutations from './favoriteProperty/mutations';
import state from './favoriteProperty/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
