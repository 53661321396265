import FavoriteProperty from '@/interfaces/favoriteProperty.interface';
import Property from '@/interfaces/property.interface';

export default {
    setFavoriteProperty({ commit }: any, payload: FavoriteProperty): void {
        commit('setFavoriteProperty', payload);
    },

    setIsLoggedIn({ commit }: any, value: boolean): void {
        commit('setIsLoggedIn', value);
    },

    setName({ commit }: any, value: string): void {
        commit('setName', value);
    },

    setIsDrawingArea({ commit }: any, payload: boolean): void {
        commit('setIsDrawingArea', payload);
    },

    setCurrentProperty({ commit }: any, payload: Property | null): void {
        commit('setCurrentProperty', payload);
    },

    setFavoriteProperties({ commit }: any, payload: FavoriteProperty[]): void {
        commit('setFavoriteProperties', payload);
    },
};
