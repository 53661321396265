
import Vue, { PropType } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Property from '@/interfaces/property.interface';
import FavoriteProperty from '@/interfaces/favoriteProperty.interface';
import tracking from '@/functions/tracking';

interface IData {
    buttonText: string;
    isSaving: boolean;
    addAnimationIn: boolean;
    addAnimationOut: boolean;
}

interface ITexts {
    savedLabel: string;
    removedLabel: string;
    saveLabel: string;
}

export default {
    data(): IData {
        return {
            buttonText: '',
            isSaving: false,
            addAnimationIn: false,
            addAnimationOut: false,
        };
    },

    mounted(): void {
        const buttonTextElement = this.$refs.buttonText as HTMLSpanElement;

        buttonTextElement?.addEventListener('animationend', (e) => {
            const animName = (e as AnimationEvent).animationName;
            if (animName === 'animateIn') {
                setTimeout(() => {
                    this.addAnimationOut = true;
                }, 3000);
            }

            if (animName === 'animateOut') {
                this.addAnimationOut = false;
                this.addAnimationIn = false;
            }
        });
    },

    props: {
        property: {
            type: Object as PropType<Property>,
            default: () => ({}),
        },
        texts: {
            type: Object as PropType<ITexts>,
            default: () => ({
                saveLabel: 'Gem som favorit',
                savedLabel: 'Gemt',
                removedLabel: 'Slettet',
            }),
        },
    },

    watch: {
        propertyIsFavorite(newValue: boolean, oldValue: boolean): void {
            this.isSaving = true;
            this.addAnimationIn = true;
            if (newValue !== oldValue) {
                setTimeout(() => {
                    this.isSaving = false;
                }, 3000);
            }
            this.buttonText = newValue
                ? this.texts.savedLabel
                : this.texts.removedLabel;
        },
    },

    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            favoriteProperties: 'user/favoriteProperties',
        }),

        propertyIsFavorite(): boolean {
            const isFound = this.favoriteProperties.some(
                (element: FavoriteProperty) => {
                    if (
                        element.propertyId === this.property.propertyId &&
                        element.brokerId === this.property.brokerId
                    ) {
                        return true;
                    }
                    return false;
                },
            );

            return isFound;
        },
    },

    methods: {
        ...mapActions({
            setFavoriteProperty: 'user/setFavoriteProperty',
            setCurrentProperty: 'user/setCurrentProperty',
            open: 'favoriteProperty/open',
        }),

        onClick(e: MouseEvent | TouchEvent): void {
            e.stopPropagation();
            e.preventDefault();
            this.setCurrentProperty(this.property);

            this.setFavoriteProperty({
                propertyId: this.property.propertyId,
                brokerId: this.property.brokerId,
            });

            Vue.nextTick(() => {
                const isFavorite = this.propertyIsFavorite;

                tracking.trackRawEvent({
                    event: 'trackCta',
                    eventData: {
                        category: 'Favoritter',
                        action: isFavorite
                            ? 'Fjern som favorit'
                            : 'Gem som favorit',
                        label: 'Preview - Direkte',
                    },
                });
            });
        },

        onNotLoggedInClick(e: MouseEvent | TouchEvent): void {
            e.stopPropagation();
            e.preventDefault();

            this.setCurrentProperty(this.property);
            this.$store.dispatch('modal/open', 'favoriteProperty');
            tracking.trackRawEvent({
                event: 'trackCta',
                eventData: {
                    category: 'Favoritter',
                    action: 'Gem som favorit initieret',
                    label: 'Preview',
                },
            });
        },
    },
};
