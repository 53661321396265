import Property from '@/interfaces/property.interface';
import State from './interface';
import FavoriteProperty from '@/interfaces/favoriteProperty.interface';

export default {
    favoriteProperties(state: State): FavoriteProperty[] {
        return state.favoriteProperties;
    },

    isLoggedIn(state: State): boolean {
        return state.isLoggedIn;
    },

    name(state: State): string {
        return state.name;
    },

    currentProperty(state: State): Property | null {
        return state.currentProperty;
    },
};
