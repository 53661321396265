// Globally available (uncomment, NeighborhoodPage.vue): import mBarChart from './components/molecules/chart/BarChart.vue';
// Globally available (uncomment, NeighborhoodPage.vue): import mDoughnutChart from './components/molecules/chart/DoughnutChart.vue';
// Globally available (uncomment, NeighborhoodPage.vue): import mHorizontalChart from './components/molecules/chart/HorizontalChart.vue';
import { defineAsyncComponent, defineComponent } from 'vue';
import PortalVue from 'portal-vue';
import VueSlider from 'vue-slider-component';
import { polyfill as seamlessScrollPolyfill } from 'seamless-scroll-polyfill';
import { createApp } from 'vue';
import Vue3TouchEvents from 'vue3-touch-events';
import { mapActions, mapGetters } from 'vuex';
import aElementScroll from './components/atoms/ElementScroll.vue';
import aEnergyLabel from './components/atoms/EnergyLabel.vue';
import aGridOverlay from './components/atoms/GridOverlay.vue';
import aIcon from './components/atoms/Icon.vue';
import mAdvancedTable from './components/molecules/AdvancedTable.vue';
import mButton from './components/molecules/Button.vue';
import oBuyerDirectoryOverlay from './components/organisms/buyerDirectory/BuyerDirectoryOverlay.vue';
import mBarChart from './components/molecules/chart/BarChart.vue';
import mBuyerDirectoryAgentButton from './components/molecules/BuyerDirectoryAgentButton.vue';
import mCtaWidget from './components/molecules/CtaWidget.vue';
import mDoughnutChart from './components/molecules/chart/DoughnutChart.vue';
import mHorizontalChart from './components/molecules/chart/HorizontalChart.vue';
import mInfoModal from './components/molecules/InfoModal.vue';
import mMedia from './components/molecules/Media.vue';
import mModalOpener from './components/molecules/ModalOpener.vue';
import mTable from './components/molecules/Table.vue';
import mInput from './components/molecules/form/Input.vue';
import mTextarea from './components/molecules/form/Textarea.vue';
import oAnchors from './components/organisms/Anchors.vue';
import oStickyAnchors from './components/organisms/StickyAnchors.vue';
import oBreadcrumb from './components/organisms/Breadcrumb.vue';
import oForm from './components/organisms/Form.vue';
import oHeader from './components/organisms/Header.vue';
import oHiddenText from './components/organisms/HiddenText.vue';
import oModal from './components/organisms/Modal.vue';
import oNavigation from './components/organisms/Navigation.vue';
import oNewsletter from './components/organisms/Newsletter.vue';
import oSnapSwiper from './components/organisms/SnapSwiper.vue';
import oAvailableBuyers from './components/organisms/availableBuyers/AvailableBuyers.vue';
import oBuyerAdviceTeam from './components/organisms/buyerAdvice/BuyerAdviceTeam.vue';
import oBuyerAdviceTeamGrid from './components/organisms/buyerAdvice/BuyerAdviceTeamGrid.vue';
import oBrokerButton from './components/organisms/broker/BrokerButton.vue';
import oBrokerContactOverlay from './components/organisms/broker/BrokerContactOverlay.vue';
import oBrokerGrid from './components/organisms/broker/BrokerGrid.vue';
import oBrokerOurCustomersSay from './components/organisms/broker/BrokerOurCustomersSay.vue';
import oBrokerPresentationHero from './components/organisms/broker/BrokerPresentationHero.vue';
import oBrokerSalesValuation from './components/organisms/broker/BrokerSalesValuation.vue';
import oBrokerPresentationTeam from './components/organisms/broker/BrokerPresentationTeam.vue';
import oBrokerPreview from './components/organisms/broker/BrokerPreview.vue';
import oEmployeeCard from './components/organisms/broker/EmployeeCard.vue';
import oBuyerAdviceForm from './components/organisms/buyerAdvice/BuyerAdviceForm.vue';
import oContactBrokerModal from './components/organisms/contactBrokerModal/ContactBrokerModal.vue';
import oFindBuyer from './components/organisms/findBuyer/FindBuyer.vue';
import oFindBuyerOverlay from './components/organisms/findBuyer/FindBuyerOverlay.vue';
import oFindBuyerResult from './components/organisms/findBuyer/FindBuyerResult.vue';
import oHomeEstimateBrokerContact from './components/organisms/homeEstimate/HomeEstimateBrokerContact.vue';
import oHomeEstimateFlow from './components/organisms/homeEstimate/HomeEstimateFlow.vue';
import oHomeEstimateLandingHero from './components/organisms/homeEstimate/HomeEstimateLandingHero.vue';
import oHomeEstimateParameterExplainer from './components/organisms/homeEstimate/HomeEstimateParameterExplainer.vue';
import oHomeEstimateResultHero from './components/organisms/homeEstimate/HomeEstimateResultHero.vue';
import oHomeEstimateResultMap from './components/organisms/homeEstimate/HomeEstimateResultMap.vue';
import oHomeEstimateSlider from './components/organisms/homeEstimate/HomeEstimateSlider.vue';
import oKeyNumbers from './components/organisms/keyNumbers/KeyNumbers.vue';
import oModuleAccordion from './components/organisms/module/ModuleAccordion.vue';
import oNeighborhoodMap from './components/organisms/neighborhood/NeighborhoodMap.vue';
import oNeighborhoodBrokerOverlay from './components/organisms/neighborhood/NeighborhoodBrokerOverlay.vue';
import oNeighborhoodPageLocal from './components/organisms/neighborhoodPage/NeighborhoodPageLocal.vue';
import oSectionHeroWithUsp from './components/organisms/module/SectionHeroWithUsp.vue';
import oOrderAssessment from './components/organisms/orderAssessment/OrderAssessment.vue';
import oPropertyEstimateTeaser from './components/organisms/property/PropertyEstimateTeaser.vue';
import oPropertyPreview from './components/organisms/property/PropertyPreview.vue';
import oSalesValuation from './components/organisms/salesValuation/SalesValuation.vue';
import oSearchHeader from './components/organisms/search/SearchHeader.vue';
import oSegmentationStep from './components/organisms/segmentationStep/SegmentationStep.vue';
import oPropertyBuyersDirectoryNotForSalePromoBox from './components/organisms/property/PropertyBuyersDirectoryNotForSalePromoBox.vue';
import oPointsOfInterest from './components/organisms/pointsOfInterest/PointsOfInterest.vue';

import oPropertyButton from '@/components/organisms/property/PropertyButton.vue';
import oPropertyMap from '@/components/organisms/property/PropertyMap.vue';
import oPropertyFloorplan from '@/components/organisms/property/PropertyFloorplan.vue';
import oPropertyOverlay from '@/components/organisms/property/PropertyOverlay.vue';
import oPropertyViews from '@/components/organisms/property/PropertyViews.vue';
import oPropertyGallery from '@/components/organisms/property/PropertyGallery.vue';
import oPropertyInteractiveGallery from '@/components/organisms/property/PropertyInteractiveGallery.vue';
import oPropertyDetails from '@/components/organisms/property/PropertyDetails.vue';
import oPropertyDetailsList from '@/components/organisms/property/PropertyDetailsList.vue';
import oPropertyFavoriteButton from '@/components/organisms/property/PropertyFavoriteButton.vue';
import oPropertyFavoriteOverlay from '@/components/organisms/property/PropertyFavoriteOverlay.vue';

import oProjectSaleOverlay from '@/components/organisms/projectSale/ProjectSaleOverlay.vue';
import oProjectSaleGallery from '@/components/organisms/projectSale/ProjectSaleGallery.vue';

import oArticleHero from './components/organisms/article/ArticleHero.vue';

// import tProperty from './components/templates/Property.vue';
// import tProjectSales from './components/templates/ProjectSale.vue';
import { EventBus } from './functions/eventBus';
import store from './store';
import Api from './functions/api';
import FavoritePropertiesResponse from './interfaces/responses/favoritePropertiesResponse.interface';
import CustomerResponse from './interfaces/responses/customerResponse.interface';
import Customer from './interfaces/customer.interface';
import { set } from 'lodash';

// const tArticle = () =>
//     import(
//         /* webpackChunkName: "article" */ './components/templates/Article.vue'
//     );
// const tBroker = () =>
//     import(
//         /* webpackChunkName: "broker" */ './components/templates/Broker.vue'
//     );
// const tBrokerSearch = () =>
//     import(
//         /* webpackChunkName: "brokerSearch" */ './components/templates/BrokerSearch.vue'
//     );
// const tEstimate = () =>
//     import(
//         /* webpackChunkName: "estimate" */ './components/templates/Estimate.vue'
//     );
// const tMunicipality = () =>
//     import(
//         /* webpackChunkName: "municipality" */ './components/templates/Municipality.vue'
//     );
// const tNeighborhood = () =>
//     import(
//         /* webpackChunkName: "neighborhood" */ './components/templates/Neighborhood.vue'
//     );
// const tNeighborhoodPage = () =>
//     import(
//         /* webpackChunkName: "neighborhoodPage" */ './components/templates/NeighborhoodPage.vue'
//     );
// const tSearch = () =>
//     import(
//         /* webpackChunkName: "search" */ './components/templates/Search.vue'
//     );
// const tStyleguide = () =>
//     import(
//         /* webpackChunkName: "styleguide" */ './components/templates/Styleguide.vue'
//     );
// const tBuyerDirectory = () =>
//     import(
//         /* webpackChunkName: "buyerDirectory" */ './components/templates/BuyerDirectory.vue'
//     );
// const tProperty = () =>
//     import(
//         /* webpackChunkName: "property" */ './components/templates/Property.vue'
//     );
// const tProjectSales = () =>
//     import(
//         /* webpackChunkName: "projectSales" */ './components/templates/ProjectSale.vue'
//     );
const tArticle = defineAsyncComponent(
    () => import('./components/templates/Article.vue'),
);
const tBroker = defineAsyncComponent(
    () => import('./components/templates/Broker.vue'),
);
const tBrokerSearch = defineAsyncComponent(
    () => import('./components/templates/BrokerSearch.vue'),
);
const tEstimate = defineAsyncComponent(
    () => import('./components/templates/Estimate.vue'),
);
const tMunicipality = defineAsyncComponent(
    () => import('./components/templates/Municipality.vue'),
);
const tNeighborhood = defineAsyncComponent(
    () => import('./components/templates/Neighborhood.vue'),
);
const tNeighborhoodPage = defineAsyncComponent(
    () => import('./components/templates/NeighborhoodPage.vue'),
);
const tSearch = defineAsyncComponent(
    () => import('./components/templates/Search.vue'),
);
const tStyleguide = defineAsyncComponent(
    () => import('./components/templates/Styleguide.vue'),
);
const tBuyerDirectory = defineAsyncComponent(
    () => import('./components/templates/BuyerDirectory.vue'),
);

const tProperty = defineAsyncComponent(
    () => import('./components/templates/Property.vue'),
);

const tProjectSales = defineAsyncComponent(
    () => import('./components/templates/ProjectSale.vue'),
);

seamlessScrollPolyfill();

// TODO: DELETE OR USE
// POSSIBLE WAY TO FIX ANY TYPE TO APP.COMPONENT
// declare module '@vue/runtime-core' {
//     export interface GlobalComponents {
//         oPointsOfInterest: typeof oPointsOfInterest;
//     }
// }

const danboligApp = {
    data() {
        return {
            footer: {
                form: {
                    email: '',
                },
            },
        };
    },

    computed: {
        ...mapGetters({
            trackingEntrance: 'modal/trackingEntrance',
            favoriteProperties: 'user/favoriteProperties',
            isLoggedIn: 'user/isLoggedIn',
        }),
        /**
         * Get current modal name from the store.
         *
         * @return {string}
         */
        currentModal(): string {
            return this.$store.getters['modal/type'];
        },

        /**
         * Get current timestamp from the document from the store.
         *
         * @return {Date | undefined}
         */
        timeStamp(): Date | undefined {
            return this.$store.getters['document/timestamp'];
        },
    },

    mounted(): void {
        // cache images in the browser
        const brokerImage1 = new Image(152, 35);
        brokerImage1.src = '/assets/images/logo.svg';
        const brokerImage2 = new Image(152, 35);
        brokerImage2.src = '/assets/images/logo_white.svg';

        if (EventBus.hasTouch) {
            document.querySelector('body')?.setAttribute('touch', '');
        }

        if (EventBus.hasMouse) {
            document.querySelector('body')?.setAttribute('mouse', '');
        }

        EventBus.$on('app.click', () => {
            document.querySelector('body')?.removeAttribute('screen-reader');
        });

        EventBus.$on('app.keyup', (keycode: string) => {
            if (keycode === 'Tab') {
                document
                    .querySelector('body')
                    ?.setAttribute('screen-reader', '');
            }
        });

        EventBus.$on('app.scroll', () => {
            if (window.scrollY > 80) {
                document.querySelector('body')?.classList.add('isScrolled');
            } else {
                document.querySelector('body')?.classList.remove('isScrolled');
            }
        });

        // Det asynkrone callback udløses når en bruger klikker på accepter-knappen på samtykke-dialogen og hver gang en bruger,
        // der tidligere har afgivet positivt samtykke, indlæser en side.
        window.addEventListener(
            'CookiebotOnAccept',
            () => {
                if (window.Cookiebot?.consent.marketing) {
                    const HOURS_TO_EXCEED = 4;
                    let HOURS_PASSED = 0;

                    this.setTimeStamp(new Date());

                    if (
                        !document.referrer.includes(document.location.hostname)
                    ) {
                        document.referrer !== ''
                            ? this.setReferrer(document.referrer)
                            : null;

                        let params = new URLSearchParams(
                            document.location.search,
                        );
                        let utmSource = params.get('utm_source') || '';
                        let utmMedium = params.get('utm_medium') || '';
                        let utmCampaign = params.get('utm_campaign') || '';
                        let utmContent = params.get('utm_content') || '';

                        utmSource !== '' ? this.setUtmSource(utmSource) : null;
                        utmMedium !== '' ? this.setUtmMedium(utmMedium) : null;
                        utmCampaign !== ''
                            ? this.setUtmCampaign(utmCampaign)
                            : null;
                        utmContent !== ''
                            ? this.setUtmContent(utmContent)
                            : null;
                    }

                    let msMinute = 60 * 1000;
                    let msHour = 60 * msMinute;
                    let msDay = 24 * msHour;
                    // let a = new Date(2012, 2, 12, 20, 59, 59);
                    // let b = new Date(2012, 2, 12, 23, 59, 59);
                    // console.log(Math.floor((b.getTime() - a.getTime()) / msDay) + ' full days between'); // 364
                    // console.log(Math.floor(((b.getTime() - a.getTime()) % msDay) / msMinute) + ' full minutes between');
                    // console.log(Math.floor(((b.getTime() - a.getTime()) % msDay) / msHour) + ' full hours between');

                    const timeThen = this.timeStamp;
                    const timeNow = new Date();

                    if (timeThen instanceof Date) {
                        const timePassed = Math.floor(
                            ((timeNow.getTime() -
                                new Date(timeThen).getTime()) %
                                msDay) /
                                msHour,
                        );
                        HOURS_PASSED = timePassed;
                    }

                    // Check timestamp, delete if overskredet
                    if (HOURS_PASSED > HOURS_TO_EXCEED) {
                        this.setReferrer('');
                        this.setUtmSource('');
                        this.setUtmMedium('');
                        this.setUtmCampaign('');
                        this.setUtmContent('');
                        this.setTimeStamp(undefined);
                    }
                }
            },
            false,
        );

        // Det asynkrone callback udløses når en bruger afviser brugen af cookies ved at klikke på afslå-knappen i cookie-samtykke-dialogen.
        // Funktionen udløses også hver gang en bruger, der tidligere har afvist brugen af cookies, indlæser en side.
        window.addEventListener(
            'CookiebotOnDecline',
            () => {
                if (!window.Cookiebot?.consent.marketing) {
                    this.setReferrer('');
                    this.setUtmSource('');
                    this.setUtmMedium('');
                    this.setUtmCampaign('');
                    this.setUtmContent('');
                    this.setTimeStamp(undefined);
                }
            },
            false,
        );

        const el = document.querySelector('#app') as HTMLDivElement;

        this.setIsLoggedIn(
            el?.dataset?.user
                ? el.dataset.user === 'true'
                    ? true
                    : false
                : false,
        );

        if (
            (window &&
                window.location &&
                window.location.pathname.includes('/bolig/')) ||
            window.location.pathname.includes('/soeg/')
        ) {
            this.getCustomer()
                .then(() => {
                    this.getFavoriteProperties();
                })
                .catch((error) => {
                    this.setFavoriteProperties([]);
                });
        }
    },

    methods: {
        ...mapActions({
            setReferrer: 'document/setReferrer',
            setUtmSource: 'document/setUtmSource',
            setUtmMedium: 'document/setUtmMedium',
            setUtmCampaign: 'document/setUtmCampaign',
            setUtmContent: 'document/setUtmContent',
            setTimeStamp: 'document/setTimeStamp',
            setIsLoggedIn: 'user/setIsLoggedIn',
            setFavoriteProperties: 'user/setFavoriteProperties',
        }),

        getFavoriteProperties(): Promise<void> {
            return Api.getFavoriteProperties().then(
                (response: FavoritePropertiesResponse) => {
                    this.setFavoriteProperties(response.properties);
                },
            );
        },

        getCustomer(): Promise<void> {
            if (this.isLoggedIn) {
                return Api.getCustomer()
                    .then((response: CustomerResponse) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

            return new Promise((resolve, reject) => {
                reject('Du er ikke logget ind');
            });
        },
    },
};

const app = createApp(danboligApp);

app.component('a-element-scroll', aElementScroll as any)
    .component('a-energy-label', aEnergyLabel as any)
    .component('a-grid-overlay', aGridOverlay as any)
    .component('a-icon', aIcon as any)
    .component('m-advanced-table', mAdvancedTable as any)
    .component('m-button', mButton as any)
    .component('o-buyer-directory-overlay', oBuyerDirectoryOverlay as any)
    .component(
        'm-buyer-directory-agent-button',
        mBuyerDirectoryAgentButton as any,
    )
    .component('m-cta-widget', mCtaWidget as any)
    .component('m-info-modal', mInfoModal as any)
    .component('m-input', mInput as any)
    .component('m-media', mMedia as any)
    .component('m-modal-opener', mModalOpener as any)
    .component('m-table', mTable as any)
    .component('m-textarea', mTextarea as any)

    .component('m-bar-chart', mBarChart as any)
    .component('m-doughnut-chart', mDoughnutChart as any)
    .component('m-horizontal-chart', mHorizontalChart as any)

    .component('o-anchors', oAnchors as any)
    .component('o-sticky-anchors', oStickyAnchors as any)
    .component('o-breadcrumb', oBreadcrumb as any)
    .component('o-broker-button', oBrokerButton as any)
    .component('o-broker-contact-overlay', oBrokerContactOverlay as any)
    .component('o-broker-grid', oBrokerGrid as any)
    .component('o-broker-our-customers-say', oBrokerOurCustomersSay as any)
    .component('o-broker-sales-valuation', oBrokerSalesValuation as any)
    .component('o-broker-presentation-team', oBrokerPresentationTeam as any)
    .component('o-buyer-advice-team', oBuyerAdviceTeam as any)
    .component('o-buyer-advice-team-grid', oBuyerAdviceTeamGrid as any)
    .component('o-employee-card', oEmployeeCard as any)
    .component('o-broker-preview', oBrokerPreview as any)
    .component(
        'o-home-estimate-broker-contact',
        oHomeEstimateBrokerContact as any,
    )
    .component('o-find-buyer', oFindBuyer as any)
    .component('o-find-buyer-result', oFindBuyerResult as any)
    .component('o-find-buyer-overlay', oFindBuyerOverlay as any)
    .component('o-form', oForm as any)
    .component('o-header', oHeader as any)
    .component('o-hidden-text', oHiddenText as any)
    .component('o-modal', oModal as any)
    .component('o-module-accordion', oModuleAccordion as any)
    .component('o-neighborhood-map', oNeighborhoodMap as any)
    .component(
        'o-neighborhood-broker-overlay',
        oNeighborhoodBrokerOverlay as any,
    )
    .component('o-neighborhood-page-local', oNeighborhoodPageLocal as any)
    .component('o-navigation', oNavigation as any)
    .component('o-newsletter', oNewsletter as any)
    .component('o-sales-valuation', oSalesValuation as any)
    .component('o-search-header', oSearchHeader as any)
    .component('o-property-favorite-button', oPropertyFavoriteButton as any)
    .component('o-property-favorite-overlay', oPropertyFavoriteOverlay as any)
    .component('o-property-preview', oPropertyPreview as any)
    .component('o-property-estimate-teaser', oPropertyEstimateTeaser as any)
    .component('o-buyer-advice-form', oBuyerAdviceForm as any)
    .component('o-section-hero-with-usp', oSectionHeroWithUsp as any)
    .component('o-home-estimate-landing-hero', oHomeEstimateLandingHero as any)
    .component('o-home-estimate-result-hero', oHomeEstimateResultHero as any)
    .component('o-home-estimate-result-map', oHomeEstimateResultMap as any)
    .component('o-home-estimate-flow', oHomeEstimateFlow as any)
    .component('o-home-estimate-slider', oHomeEstimateSlider as any)
    .component('o-segmentation-step', oSegmentationStep as any)
    .component('o-snap-swiper', oSnapSwiper as any)
    .component('o-key-numbers', oKeyNumbers as any)
    .component('o-available-buyers', oAvailableBuyers as any)
    .component('o-contact-broker-modal', oContactBrokerModal as any)
    .component('o-order-assessment', oOrderAssessment as any)
    .component(
        'o-home-estimate-parameter-explainer',
        oHomeEstimateParameterExplainer as any,
    )
    .component(
        'o-property-buyers-directory-not-for-sale-promobox',
        oPropertyBuyersDirectoryNotForSalePromoBox as any,
    )
    .component('o-points-of-interest', oPointsOfInterest as any)
    .component('o-broker-presentation-hero', oBrokerPresentationHero as any)
    .component('o-article-hero', oArticleHero as any)

    .component('o-property-button', oPropertyButton as any)
    .component('o-property-map', oPropertyMap as any)
    .component('o-property-floorplan', oPropertyFloorplan as any)
    .component('o-property-overlay', oPropertyOverlay as any)
    .component('o-property-views', oPropertyViews as any)
    .component('o-property-gallery', oPropertyGallery as any)
    .component(
        'o-property-interactive-gallery',
        oPropertyInteractiveGallery as any,
    )
    .component('o-property-details', oPropertyDetails as any)
    .component('o-property-details-list', oPropertyDetailsList as any)
    .component('o-project-sale-overlay', oProjectSaleOverlay as any)
    .component('o-project-sale-gallery', oProjectSaleGallery as any)
    .component('vue-slider', {
        extends: VueSlider,
        compatConfig: {
            MODE: 3,
            COMPONENT_V_MODEL: false,
        },
    })

    .component('t-property', tProperty)
    .component('t-project-sales', tProjectSales)
    .component('t-article', tArticle)
    .component('t-broker', tBroker)
    .component('t-broker-search', tBrokerSearch)
    .component('t-estimate', tEstimate)
    .component('t-municipality', tMunicipality)
    .component('t-neighborhood', tNeighborhood)
    .component('t-neighborhood-page', tNeighborhoodPage)
    .component('t-search', tSearch)
    .component('t-styleguide', tStyleguide)
    .component('t-buyer-directory', tBuyerDirectory);

app.use(store).use(PortalVue).use(Vue3TouchEvents);

app.mount('#app');

export default app;
