
import { PropType } from 'vue';
import Format from '@/functions/format';
import Property from '@/interfaces/property.interface';
import oPropertyPreviewFavoriteButton from '@/components/organisms/property/PropertyPreviewFavoriteButton.vue';

interface OpenHouseTexts {
    teaser: string;
    teaserSignup: string;
}

export default {
    components: {
        oPropertyPreviewFavoriteButton,
    },
    props: {
        comingSoonText: {
            type: String as PropType<string>,
            default: '',
        },
        property: {
            type: Object as PropType<Property>,
            default: () => ({
                address: '',
                city: '',
                factsDesktop: [],
                factsMobile: [],
                fallbackImageUrl: '',
                hasNewPrice: false,
                images: [],
                isDanbolig: false,
                isNew: false,
                isSold: false,
                isUnderSale: false,
                isComingSoon: false,
                luxurious: false,
                propertySize: '',
                openHouse: '',
                openHouseSignupRequired: '',
                soldDate: new Date(),
                type: '',
                url: '',
                zipCode: 0,
            }),
        },
        openHouseTexts: {
            type: Object as PropType<OpenHouseTexts>,
            default: () => ({
                teaser: '',
                teaserSignup: '',
            }),
        },
        total: {
            type: Number as PropType<number | null>,
            default: () => null,
        },
        subindex: {
            type: Number as PropType<number | null>,
            default: () => null,
        },
    },

    computed: {
        /**
         * Get first image.
         *
         * @return {string}
         */
        image(): string {
            if (!this.property.images?.length) {
                return this.property.fallbackImageUrl;
            }
            const image = this.property.images[0];
            return image && image.includes('deviceid')
                ? Format.formatMindworkingUrl(image ?? '', '500', '')
                : image ?? this.property.fallbackImageUrl;
        },

        /**
         * Check if property is external.
         *
         * @return {boolean}
         */
        isExternal(): boolean {
            return !this.property.isDanbolig;
        },

        openHouseFormatted(): string {
            const template = this.property.openHouseSignupRequired
                ? this.openHouseTexts.teaserSignup
                : this.openHouseTexts.teaser;
            return template.replace(
                '#naesteaabenthus#',
                this.property.openHouse ?? '',
            );
        },

        soldDate(): string {
            if (
                this.property.soldDate !== null &&
                this.property.soldDate !== undefined
            ) {
                const date = new Date(this.property.soldDate);
                const month = Format.monthShort(date);
                const year = Format.year(date);
                return `Solgt ${month} ${year}`;
            }

            return '';
        },
    },

    methods: {
        onClick(event: Event): void {
            if (this.property.isSold) {
                event?.preventDefault();
            }
        },
    },
};
