import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  target: "_blank",
  class: "o-propertyCard__link"
}
const _hoisted_2 = { class: "o-propertyCard__wrapper" }
const _hoisted_3 = { class: "o-propertyCard__imageWrapper" }
const _hoisted_4 = {
  key: 0,
  class: "a-small o-propertyCard__tag",
  textContent: 'Anden mægler'
}
const _hoisted_5 = {
  key: 1,
  class: "a-small o-propertyCard__tag o-propertyCard__tag--liebhaver",
  textContent: 'Liebhaver'
}
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "o-propertyCard__content" }
const _hoisted_9 = { class: "a-small o-propertyCard__text" }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { class: "a-label o-propertyCard__address" }
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = {
  key: 0,
  class: "o-propertyCard__label o-propertyCard__label--underSale"
}
const _hoisted_16 = {
  key: 1,
  class: "o-propertyCard__facts"
}
const _hoisted_17 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_energy_label = _resolveComponent("a-energy-label")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["o-propertyCard", { isSold: $props.property.isSold }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          ($options.isExternal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("v-if", true),
          (
                            !$options.isExternal &&
                            $props.property.luxurious &&
                            !$props.property.isSold
                        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_5))
            : _createCommentVNode("v-if", true),
          (!$options.isExternal && $props.property.isSold && $options.soldDate !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "a-small o-propertyCard__tag",
                textContent: _toDisplayString($options.soldDate)
              }, null, 8 /* PROPS */, _hoisted_6))
            : _createCommentVNode("v-if", true),
          _createElementVNode("img", {
            src: $options.image,
            onerror: "this.onerror=null;this.style.opacity='0';",
            class: "o-propertyCard__image",
            alt: "",
            role: "presentation"
          }, null, 8 /* PROPS */, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", {
              textContent: _toDisplayString($props.property.type)
            }, null, 8 /* PROPS */, _hoisted_10),
            ($options.propertyStatus)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "a-small o-propertyCard__label",
                  textContent: _toDisplayString($options.propertyStatus)
                }, null, 8 /* PROPS */, _hoisted_11))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", {
              textContent: _toDisplayString($props.property.address)
            }, null, 8 /* PROPS */, _hoisted_13),
            _createElementVNode("p", {
              textContent: _toDisplayString(`${$props.property.zipCode} ${$props.property.city}`)
            }, null, 8 /* PROPS */, _hoisted_14)
          ]),
          ($props.property.isUnderSale)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, " Købsaftale underskrevet! "))
            : ($props.property.factsMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.property.factsMobile, (fact, factIndex) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (fact.name === 'EnergyLabel')
                        ? (_openBlock(), _createBlock(_component_a_energy_label, {
                            key: `label-${factIndex}`,
                            name: fact.value,
                            class: "o-propertyCard__fact"
                          }, null, 8 /* PROPS */, ["name"]))
                        : (_openBlock(), _createElementBlock("span", {
                            key: `span-${factIndex}`,
                            class: "a-label o-propertyCard__fact",
                            innerHTML: fact.value
                          }, null, 8 /* PROPS */, _hoisted_17))
                    ], 64 /* STABLE_FRAGMENT */))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true),
          _createCommentVNode(" <div class=\"o-propertyCard__icon\">\n                        <a-icon name=\"star_outline\" class=\"a-icon--favorite\" />\n                    </div> ")
        ])
      ])
    ])
  ], 2 /* CLASS */))
}