import MapCluster from '@/interfaces/mapCluster.interface';
import MapDot from '@/interfaces/mapDot.interface';
import MapProperty from '@/interfaces/mapProperty.interface';
import Property from '@/interfaces/property.interface';
import PropertyDocument from '@/interfaces/propertyDocument.interface';

interface RawCluster {
    geometry: {
        coordinates: [number, number];
        type: 'Point';
    };
    properties: {
        count: number;
    };
}

interface RawDot {
    geometry: {
        coordinates: [number, number];
        type: 'Point';
    };
}

interface RawProperty {
    geometry: {
        coordinates: [number, number];
        type: 'Point';
    };
    properties: {
        data: {
            address: string;
            brokerId: string;
            city: string;
            documents: PropertyDocument[];
            factsDesktop: {
                name: string;
                label: string;
                value: string;
            }[];
            factsMobile: {
                name: string;
                label: string;
                value: string;
            }[];
            fallbackImageUrl: string;
            groundSize: null;
            hasNewPrice: boolean;
            images: string[];
            isDanbolig: boolean;
            isNew: boolean;
            isSold: boolean;
            isUnderSale: boolean;
            luxurious?: boolean;
            openHouse?: string;
            openHouseShort?: string;
            openHouseSignupRequired?: boolean;
            price: string;
            propertyId: string;
            propertySize: string;
            rooms: string;
            soldDate?: Date;
            spotText?: string;
            type: string;
            url: string;
            zipCode: string;
        }[];
        label: string;
    };
}

interface RawResponse {
    level: 'clusters' | 'dots' | 'markers' | 'pins';
    results: (RawCluster | RawDot | RawProperty)[];
    totalCount: number;
}

export default class PropertiesMap {
    level: 'clusters' | 'dots' | 'markers' | 'pins' = 'markers';

    results: (MapCluster | MapDot | MapProperty)[] = [];

    totalCount: number = 0;

    /**
     * @constructor
     * @param {RawResponse} response
     */
    constructor(response: RawResponse) {
        this.level = response.level;

        this.totalCount = response.totalCount;

        this.results = response.results.map(
            (
                item: RawCluster | RawDot | RawProperty,
            ): MapCluster | MapDot | MapProperty => {
                if (response.level === 'clusters') {
                    return <MapCluster>{
                        geometry: item.geometry,
                        properties: {
                            count: (item as RawCluster).properties.count,
                            type: 'cluster',
                        },
                        type: 'Feature',
                    };
                }

                if (response.level === 'dots') {
                    return <MapDot>{
                        geometry: item.geometry,
                        properties: {
                            type: 'dot',
                        },
                        type: 'Feature',
                    };
                }

                let property = <RawProperty>item;
                return <MapProperty>{
                    geometry: property.geometry,
                    properties: {
                        data: property.properties.data.map(
                            (dataItem: any): Property => {
                                return {
                                    address: dataItem.address,
                                    brokerId: dataItem.brokerId,
                                    city: dataItem.city,
                                    documents: dataItem.documents,
                                    factsDesktop: dataItem.factsDesktop,
                                    factsMobile: dataItem.factsMobile,
                                    fallbackImageUrl: dataItem.fallbackImageUrl,
                                    hasNewPrice: dataItem.hasNewPrice,
                                    images: dataItem.images,
                                    isDanbolig: dataItem.isDanbolig,
                                    isNew: dataItem.isNew,
                                    isSold: dataItem.isSold,
                                    isUnderSale: dataItem.isUnderSale,
                                    luxurious: dataItem.luxurious,
                                    openHouse: dataItem.openHouse ?? undefined,
                                    openHouseShort:
                                        dataItem.openHouseShort ?? undefined,
                                    openHouseSignupRequired:
                                        dataItem.openHouseSignupRequired ??
                                        false,
                                    price: dataItem.price,
                                    propertyId: dataItem.propertyId,
                                    propertySize: dataItem.propertySize,
                                    soldDate: dataItem.soldDate,
                                    spotText: dataItem.spotText,
                                    type: dataItem.type,
                                    url: dataItem.url,
                                    zipCode: dataItem.zipCode,
                                };
                            },
                        ),
                        label:
                            property.properties.data.length > 1
                                ? `${property.properties.data.length} boliger`
                                : property.properties.label,
                    },
                    type: 'Feature',
                };
            },
        );
    }
}
