
import { PropType } from 'vue';
import mMap from '../../molecules/Map.vue';
import {
    BOUNDS,
    LATITUDE,
    LONGITUDE,
    MAX_BOUNDS,
    ZOOM,
} from '../../../constants/search.const';
import Api from '../../../functions/api';
import MapCluster from '../../../interfaces/mapCluster.interface';
import MapDot from '../../../interfaces/mapDot.interface';
import MapFavorite from '../../../interfaces/mapFavorite.interface';
import MapNeighborhood from '../../../interfaces/mapNeighborhood.interface';
import MapProperty from '../../../interfaces/mapProperty.interface';
import FavoritesMapResponse from '../../../interfaces/responses/favoritesMapResponse.interface';
import NeighborhoodsMapResponse from '../../../interfaces/responses/neighborhoodsMapResponse.interface';
import PropertiesMapResponse from '../../../interfaces/responses/propertiesMapResponse.interface';
import BrokersMapResponse from '../../../interfaces/responses/brokersMapResponse.interface';
import MapBroker from '../../../interfaces/mapBroker.interface';
import { EventBus } from '@/functions/eventBus';

interface Data {
    bounds: number[][];
    brokers: MapBroker[];
    favorites: MapFavorite[];
    isLoading: boolean;
    latitude: number;
    longitude: number;
    maxBounds: { lat: number; lng: number }[];
    neighborhoods: (MapCluster | MapNeighborhood)[];
    properties: (MapCluster | MapDot | MapProperty)[];
    zoom: number;
}

export interface Texts {
    openHouseTeaser: string;
    openHouseTeaserSignup: string;
}

export default {
    components: {
        mMap,
    },

    props: {
        municipalityId: {
            type: Number as PropType<number>,
            required: false,
        },
        neighborhoodId: {
            type: Number as PropType<number>,
            required: false,
        },
        polygon: {
            type: Array as PropType<number[][]>,
            default: () => [],
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                openHouseTeaser: '',
                openHouseTeaserSignup: '',
            }),
        },
        isComingSoon: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    data(): Data {
        return {
            bounds: BOUNDS,
            brokers: [],
            favorites: [],
            isLoading: true,
            latitude: LATITUDE,
            longitude: LONGITUDE,
            maxBounds: MAX_BOUNDS,
            neighborhoods: [],
            properties: [],
            zoom: EventBus.isDesktop ? ZOOM : ZOOM - 1,
        };
    },
    methods: {
        getData(): void {
            this.isLoading = true;
            this.getNeighborhoods();

            if (this.neighborhoodId) {
                const propertiesPromise = this.getProperties();
                const favoritesPromise = this.getFavorites();
                const brokersPromise = this.getBrokers();

                Promise.all([
                    propertiesPromise,
                    favoritesPromise,
                    brokersPromise,
                ]).then(() => {
                    this.isLoading = false;
                });
            } else {
                this.isLoading = false;
            }
        },

        getFavorites(): void {
            Api.getFavoritesMap({
                neighborhoodId: this.neighborhoodId,
            }).then((response: FavoritesMapResponse) => {
                this.favorites = response.results;
            });
        },

        getBrokers(): void {
            Api.getBrokers({
                query: '',
            }).then((response: BrokersMapResponse) => {
                this.brokers = response.results;
            });
        },

        getNeighborhoods(): Promise<void> {
            return Api.getNeighborhoodsMap({
                bounds: this.bounds,
                municipalityId: this.municipalityId ?? undefined,
                neighborhoodId: this.neighborhoodId ?? undefined,
                zoom: this.zoom,
            }).then((response: NeighborhoodsMapResponse) => {
                this.neighborhoods = response.results;
            });
        },

        getProperties(): void {
            if (this.isComingSoon) {
                this.properties = [];
                return;
            }

            Api.getPropertiesMap({
                bounds: this.bounds,
                neighborhoodId: this.neighborhoodId,
                zoom: this.zoom,
            }).then((response: PropertiesMapResponse) => {
                this.properties = response.results;
            });
        },

        /**
         * Handle "load" event on map.
         *
         * @return {void}
         */
        onLoad(): void {
            this.getData();
        },

        /**
         * Handle "move" event on map.
         *
         * @param {any} event
         * @return {void}
         */
        onMove(payload: any): void {
            this.latitude = payload.latitude;
            this.longitude = payload.longitude;
            this.zoom = payload.zoom;

            const northEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._ne.lat,
            ];
            const northWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._ne.lat,
            ];
            const southEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._sw.lat,
            ];
            const southWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._sw.lat,
            ];

            this.bounds = [
                southWest,
                southEast,
                northEast,
                northWest,
                southWest,
            ];
            this.getData();
        },
    },
};
