
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';

export default {
    props: {
        alt: {
            type: String as PropType<string>,
            default: () => '',
        },
        image: {
            type: String as PropType<string>,
            default: () => '',
        },
        body: {
            type: String as PropType<string>,
            default: () => '',
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
        },
        isComingSoon: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    computed: {
        formattedUrl(): string {
            if (!this.image) return '';

            if (this.image && this.image.includes('deviceid'))
                return Format.formatMindworkingUrl(this.image, '84', '84', '');

            return this.image + '?height=84&width=84';
        },

        hasImage(): boolean {
            return this.formattedUrl.length > 0;
        },
    },

    methods: {
        ...mapActions({
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),

        /**
         * Handle click event.
         *
         * @return {void}
         */
        onClick(): void {
            this.$store.dispatch('property/open', '');
            this.setTrackingEntrance('Kontaktbar modul');

            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Boligpraesentation indgang',
                    label: 'Boligpraesentation',
                    formularIndgang: 'Kontaktbar modul',
                    formularStepnavn: `Formularoverblik${this.isComingSoon ? ' - Kommer snart' : ''}`,
                    maeglerId: this.brokerId ?? '',
                },
            });
        },
    },
};
